import React, { useEffect, useState, useCallback } from "react";
import { H2, Label, Description } from "@leafygreen-ui/typography";
import Toggle from "@leafygreen-ui/toggle";
import Button from "@leafygreen-ui/button";
import Icon from '@leafygreen-ui/icon';
import { css } from "@leafygreen-ui/emotion";
import { Spinner } from "@leafygreen-ui/loading-indicator";
import TextInput from "@leafygreen-ui/text-input";
import Pagination from "@leafygreen-ui/pagination";
import ShortiesTable from "./ShortiesTable";
import ChartModal from "./ChartModal";
import QrCodeModal from "./QrCodeModal";
import { useAuth0 } from "@auth0/auth0-react";
import { useApi } from "../../providers/Api";
import ShortieModal from "./ShortieModal";

export default function Routes() {
  const [insertModalOpened, setInsertModalOpened] = useState(false);
  const [qrCodeModalOpened, setQrCodeModalOpened] = useState(false);
  const [qrCodeDestination, setQrCodeDestination] = useState("");
  const [chartModalOpened, setChartModalOpened] = useState(false);
  const [chartRoute, setChartRoute] = useState("");
  const [data, setData] = useState([]);
  const [modalMode, setModalMode] = useState("add");
  const [showMyRoutes, setShowMyRoutes] = useState(true);
  const [routeToEdit, setRouteToEdit] = useState({});
  const [doneLoading, setDoneLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [paginationCurrentPage, setPaginationCurrentPage] = useState(1);
  const [paginationTotalPages, setPaginationTotalPages] = useState(1);
  const [paginationNumTotalItems, setPaginationNumTotalItems] = useState(0);
  const [currentView, setCurrentView] = useState([]);

  const { user } = useAuth0();
  const currentUserId = user.sub;

  const { fetchRoutes, deleteRoute } = useApi();

  const itemsPerPage = 10;

  const qrCodeModalStyle = css`
    text-align: center;
    max-height: 100vh;
  `;

  const topBarStyle = css`
    text-align: right;
    margin-bottom: 20px;
    .minebar {
      padding-top: 10px;
    }
    .searchbar {
      label {
        text-align: left
      }
      padding-top: 20px;
    }
  `;

  const toggleButtonStyle = css`
    padding-right: 30px;
    display: block;
    float: left;
    text-align: left;
  `;

  const searchInputStyle = css`
    width: 300px;
    margin-right: 20px;
  `;

  const showQrCode = async (route) => {
    setQrCodeModalOpened(true);
    setQrCodeDestination(`mdb.link${route}`);
  };

  const showChartModal = async (route) => {
    setChartModalOpened(true);
    setChartRoute(route);
  };

  const handleDelete = async (id) => {
    await deleteRoute(id);
    getData();
  };

  const handleEditRoute = async (route) => {
    setModalMode("edit");
    setInsertModalOpened(true);
    setRouteToEdit(route);
  };

  const handleSearch = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    setSearchTerm(searchTerm);
  };

  const fullWidthSearchStyle = css`
    width: 100%;
    margin-bottom: 20px;
  `;

  const showFilterView = () => {
    let filteredView = data;
    if (showMyRoutes) {
      filteredView = data.filter((datum) => {
        const isOneOfMyRoutes = Array.isArray(datum.owner)
            ? datum.owner.includes(currentUserId)
            : (datum.owner === currentUserId);
        return isOneOfMyRoutes;
      });
    }

    if (searchTerm && searchTerm.length > 0) {
      filteredView = filteredView.filter(i => {
        return i.route.toLowerCase().includes(searchTerm) ||
          i.to.toLowerCase().includes(searchTerm);
      });
    }

    const numTotalItems = filteredView.length;
    setPaginationNumTotalItems(numTotalItems);
    setPaginationTotalPages(Math.ceil(numTotalItems / itemsPerPage));

    let pageIndex = paginationCurrentPage - 1;
    if (pageIndex < 0) pageIndex = 0;
    let firstItem = ((pageIndex * itemsPerPage));
    let lastItem = Math.min((firstItem + itemsPerPage), numTotalItems);
    filteredView = filteredView.slice(firstItem, lastItem);
    setCurrentView(filteredView);
  };

  useEffect(() => {
    showFilterView();
  }, [paginationCurrentPage, showMyRoutes, searchTerm, data]);

  const paginateForward = () => {
    paginate(1);
  };

  const paginateBackward = () => {
    paginate(-1);
  }

  const paginate = (step) => {
    const maxPage = paginationTotalPages;
    let newPage = paginationCurrentPage + step;
    if (newPage < 1) newPage = 1;
    if (newPage > maxPage) newPage = maxPage;
    setPaginationCurrentPage(newPage);
  };

  const getData = useCallback(async () => {
    setDoneLoading(false);
    let results = await fetchRoutes();
    setData(results);
    setDoneLoading(true);
  }, [fetchRoutes]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <React.Fragment>
      <H2>List of Short URLs</H2>

      <div className={topBarStyle}>
        <div className="minebar">
          <div className={toggleButtonStyle}>
            <Label id="toggleLabel" htmlFor="myLandingsToggle">
              Show Mine
            </Label>
            <Description>Show only landing pages that I have created</Description>
          </div>
          <div style={{ display: "block", float: "left" }}>
            <Toggle
              id="myLandingsToggle"
              aria-labelledby="toggleLabel"
              onChange={(checked) => setShowMyRoutes(checked)}
              checked={showMyRoutes}
            />
          </div>
        </div>
        <div className="buttonbar">
          <Button
            onClick={() => setInsertModalOpened(true)}
            variant="primary"
            leftGlyph={<Icon glyph="Plus" />}
          >
            Insert New Short URL
          </Button>
        </div>
        <div className="searchbar">
          <TextInput
            label="Search Short URLs"
            placeholder="Search by Short URL or Destination"
            onChange={handleSearch}
            value={searchTerm}
            className={fullWidthSearchStyle}
            />
        </div>
      </div>

      <ShortieModal
        open={insertModalOpened}
        modalMode={modalMode}
        setOpen={setInsertModalOpened}
        setModalMode={setModalMode}
        allRoutes={data}
        refreshData={getData}
        routeToEdit={routeToEdit}
      />

      <QrCodeModal
        open={qrCodeModalOpened}
        setOpen={setQrCodeModalOpened}
        qrCodeDestination={qrCodeDestination}
        className={qrCodeModalStyle}
      />

      <ChartModal
        open={chartModalOpened}
        setOpen={setChartModalOpened}
        chartRoute={chartRoute}
      />

      {doneLoading ? (
        <>
          <ShortiesTable
            data={currentView}
            currentUserId={currentUserId}
            getData={getData}
            editRoute={handleEditRoute}
            allRoutes={data}
            handleDelete={handleDelete}
            showQrCode={showQrCode}
            showChartModal={showChartModal}
          />
          <div>
          <Pagination
            itemsPerPage={itemsPerPage}
            currentPage={paginationCurrentPage}
            numTotalItems={paginationNumTotalItems}
            onForwardArrowClick={() => paginateForward()}
            onBackArrowClick={() => paginateBackward()}
          />
        </div>
        </>
      ) : (
        <Spinner description="Loading..." displayOption="large-vertical" />
      )}
    </React.Fragment>
  );
}