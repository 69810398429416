import React, { useState, useEffect } from "react";
import { H2 } from "@leafygreen-ui/typography";
import TextInput from '@leafygreen-ui/text-input';
import Copyable from "@leafygreen-ui/copyable";
import { css } from "@leafygreen-ui/emotion";
import MultiBox from "../components/MultiBox";
import { Combobox, ComboboxOption } from "@leafygreen-ui/combobox";
import { useAuth0 } from "@auth0/auth0-react";
import { useApi } from "../providers/Api";


export default function Home () {
  const { user } = useAuth0();

  let [url, setURL] = useState("");
  let [campaign, setCampaign] = useState("");
  let [source, setSource] = useState("");
  let [medium, setMedium] = useState("");
  let [isMediumValid, setMediumValid] = useState(true);
  let [content, setContent] = useState("");
  let [term, setTerm] = useState("");
  let [urlValid, setUrlValid] = useState(true);
  let [linkWithUTM, setLinkWithUTM] = useState("");
  let [utmDataOptions, setUtmDataOptions] = useState();

  const { profile } = useApi();

  const mediumsAvailableValues = [
    'cpc_paid_search',
    'ps_paid_social',
    'referral',
    'display',
    'email',
    'product',
    'organic_social',
    'qr_code',
    'influencer',
    'event',
    'other'
  ];

  useEffect(() => {
    setUtmDataOptions(profile.utms);
  }, [profile]);

  useEffect(() => {
    let trueURL = url;
    if (url && !trueURL.match(/http[s?]/)) trueURL = "https://" + trueURL;
    trueURL = trueURL.replace("http://", "https://");

    if (campaign || source || medium || content || term) {
      trueURL.includes("?") ? trueURL += "&" : trueURL += "?";
      if (campaign) trueURL = `${trueURL}utm_campaign=${encodeURIComponent(campaign.toLowerCase())}&`;
      if (source) trueURL = `${trueURL}utm_source=${encodeURIComponent(source.toLowerCase())}&`;
      if (medium) trueURL = `${trueURL}utm_medium=${encodeURIComponent(medium.toLowerCase())}&`;
      if (content) trueURL = `${trueURL}utm_content=${encodeURIComponent(content.toLowerCase())}&`;
      if (term) trueURL = `${trueURL}utm_term=${encodeURIComponent(term.toLowerCase())}&`;
    }
    if (trueURL.slice(-1) === "&") trueURL = trueURL.substring(0, trueURL.length-1);
    setLinkWithUTM(trueURL);
  }, [url, campaign, source, medium, content, term]);

  const formStyle = css`
    margin: 0px 50px;
    max-width: 50em;
  `

  const copyableStyle = css`
    margin: 0px 50px;
    width: 50em;
  `

  const copyableInputStyle = css`
    width: 50em;
  `

  return  (
    <React.Fragment>
      <H2>UTM Link Builder</H2>

      <p>
        <div>
          This tool aims at helping MongoDB employees add UTM campaign parameters to our MongoDB URLs to track traffic and conversion performance.
          <br />
          For detailed instructions on what UTM parameters are and how to properly set them, please read 
          <span> </span>
          <a 
            href="https://wiki.corp.mongodb.com/pages/viewpage.action?spaceKey=MAR&title=UTM+Tracking" 
            target="_blank" 
            rel="noopener noreferrer"
          >
            this wiki page
          </a>.
          <br />
          <i style={{ fontSize: "small" }}>
            Note: Avoid using UTMs for internal tracking.
          </i>
          <br />
          <br />
          Fill out the form below to generate your URL with UTM parameters (use lowercase and "_" for separators).
        </div>
      </p>

      <p>
        <form className={formStyle}>
          <TextInput
            label="URL"
            description="full MongoDB website URL (https://www.mongodb.com/....)"
            placeholder="mongodb.com/..."
            onChange={e => setURL(e.target.value) }
            onKeyUp={e => {
              if (!url.match(/mongodb\.com/)) {
                setUrlValid(false);
              } else {
                setUrlValid(true);
              }
            }}
            errorMessage="URL must be leading to a MongoDB website"
            state={urlValid ? "valid" : "error"}
            value={url}
          /><br/>
          <MultiBox
            label="Campaign Name"
            description={
              <>
                campaign title that outlines the initiative, including detailed information such as the date, format, and other relevant specifics. For examples, please refer to the wiki:{" "}
                <a 
                  href="https://wiki.corp.mongodb.com/pages/viewpage.action?spaceKey=MAR&title=UTM+Tracking" 
                  target="_blank" 
                  rel="noopener noreferrer"
                >
                  UTM Tracking Wiki
                </a>
              </>
            }            
            onChange={value => setCampaign(value.toLowerCase())}
            value={campaign}
            possibleValues={utmDataOptions?.campaigns}
          /><br/>
          <MultiBox
            label="Traffic Source (required)"
            description="traffic origin, such as a search engine (eg: google), or a domain (eg:xyz.com) , or an event (eg:.local_london_2024)"
            onChange={value => setSource(value)}
            value={source}
            possibleValues={utmDataOptions?.sources}
          /><br/>
          <Combobox
            label="Medium (required)"
            description="the medium or placement from where the link is being shared from"
            onChange={value => {
              setMedium(value);
              setMediumValid(mediumsAvailableValues.includes(value));
            }}
            value={medium}
          >
            {mediumsAvailableValues.map((value) => (
              <ComboboxOption key={value} displayName={value} value={value} />
            ))}
          </Combobox>
          <br/>
          <MultiBox
            label="Content"
            description="details further categorizing campaign name (e.g: video_name, conference_name). This is not a required field and should only be used if campaign, source and medium are not enough to differentiate similar initiatives."
            onChange={value => setContent(value)}
            value={content}
            possibleValues={utmDataOptions?.contents}
          /><br/>
          <MultiBox
            label="Term"
            description="use this to identify advocacy employees or influencers who shared the link on social media (eg: employee username). This is not a required field and should only be used if campaign, source and medium are not enough to differentiate similar initiatives."
            onChange={value => setTerm(value)}
            value={term}
            possibleValues={utmDataOptions?.terms}
          /><br/>
        </form>
      </p>
      <p className={copyableStyle}>
        <Copyable className={copyableInputStyle} label="Your Link" description="Use this link to start tracking your impact.">
          {linkWithUTM}
        </Copyable>
      </p>
    </React.Fragment>
  )
}